import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, output, ViewEncapsulation } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { getFileExtension, getFileIcon } from "@app/shared/components/file-config/shared/utils/file-word-masking.util";

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule],
  selector: 'app-file-card',
  template: `
    <div class="file-card"
         [class.file-card-full]="full"
         [class.no-border]="noBorder"
    >
      @if(onlyShow) {
        <button
          type="button"
            mat-icon-button
            class="preview-close-button btn-base"
            (click)="delete.emit()"
            >
            <mat-icon class="preview-close-icon">close</mat-icon>
        </button> 
      }
      <span class="d-flex">
        <img [src]="icon" alt="file">
      </span>
      <div class="file-name">
        <span class="name font-14px fw-500" [title]="name">
          {{ name }}
        </span>
        <span class="tc-dust font-12px fw-400">
            {{ dateSize ? dateSize : extension   }}
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./file-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FileCardComponent implements OnInit{

  @Input()
  public name: string = '';

  @Input()
  public type: string = '';

  @Input()
  public full: boolean = false;

  @Input()
  public dateSize: string = ''

  @Input()
  public noBorder!: boolean;

  @Input()
  public onlyShow: boolean = true;

  @Input()
  public ellipsis: number | null = 50;

  delete = output();

  public icon: string = '';
  public extension: string = '';

  ngOnInit(): void {
    this.extension = getFileExtension(this.type);
    this.icon = getFileIcon(this.type)
  }
}
