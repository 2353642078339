<div
  roamDropZone
  fileOverClass="is-dragging"
  (fileDropped)="onFileDropped($event)"
  class="roam-file-uploader"
  [class.file-invalid]="!isFileValid()"
  >
  @if (isUploading()) {
    <div class="spinner-wrapper">
      <mat-spinner diameter="30" />
    </div>
  }

  @if (isShowFile()) {
    @for (item of data(); track item.id) {
      @if (fileExtensionsMap.IMAGE.includes(item.extension)) {
        <app-file-image-preview
          [src]="item.fileUrl"
          [isClose]="!onlyShow()"
          (clickClose)="removeOne(item.id)"
          (clickView)="openPreviewer($index)" />
      } @else if (fileExtensionsMap.DOCUMENT.includes(item.extension)) {
        <app-file-card 
          [onlyShow]="!onlyShow()"
          [name]="item.name"
          [type]="item.extension"
          (delete)="removeOne(item.id)"
        />
      }
    }
  }

  @if (!data().length || !isShowFile()) {
    <div class="start-upload">
      @if (containerText() != "") {
        <span class="fw-600 tc-primary">{{ containerText() }}</span>
        <span> or </span>
      }
      <button
        mat-button
        type="button"
        class="upload-button"
        (click)="hiddenFileInput.click()">
        <span
          inlineSVG="assets/svg/upload-icon-black.svg"
          alt="Upload Icon"></span>
        <span class="fw-400">Upload File</span>
      </button>
    </div>
  } @else {
    <a (click)="hiddenFileInput.click()" class="add-button">
      <span class="icon-plus">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
        </svg>
      </span>
      <span class="label">Add file</span>
    </a>
  }
  
  <input
    (change)="onFileInputChanges($event)"
    [accept]="acceptExtensions()"
    style="display: none"
    #hiddenFileInput
    type="file"
    [multiple]="isMultiple() ? true : false" />
</div>

<div class="font-12px mt-12"
[ngClass]="isFileValid() ? 'tc-grey' : 'tc-warning'"
>
  {{ descriptionText() }}
</div>
